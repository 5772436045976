import { useEffect, useState } from "react";
import { DEFAULT_PFP_URL } from "@/utils/profile";
import "@/utils/firebase-init";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { Image as ChakraImage } from "@chakra-ui/react";

const storage = getStorage();

export const cacheURL = async (uid, url) => {
  localStorage.setItem(uid, url);
};

export const downloadAndCache = async (uid, shouldHttpCache) => {
  const downloadURL = await getDownloadURL(ref(storage, `pfps/${uid}.jpg`));
  cacheURL(uid, downloadURL);
  if (shouldHttpCache) {
    const img = new Image();
    img.src = downloadURL;
  }
  return downloadURL;
};

export default function ProfilePic({ uid, ...rest }) {
  const [url, setURL] = useState(DEFAULT_PFP_URL);

  const init = async () => {
    try {
      const cachedURL = localStorage.getItem(uid);
      if (cachedURL) {
        setURL(cachedURL);
        return;
      }
      setURL(await downloadAndCache(uid, false));
    } catch (e) {}
  };

  useEffect(() => {
    init();
  }, []);

  return <ChakraImage src={url} alt="Profile picture" borderRadius="full" {...rest} />;
}
