import * as colleges from "@/utils/data/colleges-data";
import { ConditionalVerifiedCheck } from "./profile/VerifiedCheck";
import { Box, Flex, Text, chakra } from "@chakra-ui/react";
import ProfilePic from "./profile/ProfilePic";
import CollegeImageList from "./profile/CollegeImageList";
import { useRouter } from "next/router";

export const ProfileCard = (props) => {
  const { id } = props;
  const router = useRouter();

  const onView = () => router.push(`/profile/${id}`);

  return (
    <Flex
      bg="white"
      borderRadius="lg"
      onClick={onView}
      cursor="pointer"
      direction="column"
      p={4}
      shadow="-6px 6px 0px 0px rgba(255, 211, 238, 0.32)"
      position="relative"
      _before={{
        pos: "absolute",
        content: '"💥"',
        top: 6,
        right: -4,
        fontSize: "4xl",
      }}
      _after={{
        pos: "absolute",
        content: '"New 2024 Profile"',
        top: -3,
        right: 2,
        fontSize: "xs",
        bg: "#000",
        color: "#fff",
        p: 1,
        borderRadius: "md",
      }}
    >
      <Flex gap={4}>
        <ProfilePic key={id} uid={id} boxSize={24} />

        <Box>
          {/* TODO: PIN props.id === PINNED_PROFILE && <Icon name='pin' size='small' /> */}
          <Text fontWeight="bold" fontSize="3xl">
            {props.display_name ?? "Anonymous"}
          </Text>

          <Flex align="center" fontWeight="bold" fontSize="lg">
            <ConditionalVerifiedCheck
              verifications={props.verification}
              university={props.university}
              mr={1}
            />

            {colleges.find(props.university)?.name ?? "Unknown University"}

            {props.application_year && "‘" + (props.application_year % 100)}
          </Flex>

          {props.bio && (
            <Text noOfLines={2} fontSize="lg" color="#414B5A">
              {props.bio}
            </Text>
          )}
        </Box>
      </Flex>

      <Text fontWeight="bold" mt={2} fontSize="xl">
        Stats
      </Text>

      <Flex wrap="wrap" gap={1} mt={1}>
        <Flex
          borderRadius="full"
          minW="50px"
          p={2}
          flexDir="column"
          textAlign="center"
          bg="#F0F2F5"
        >
          <chakra.span fontSize="lg" fontWeight={600} h={5}>
            {props.n_colleges}
          </chakra.span>

          <chakra.span fontSize="0.6rem" color="#414B5A">
            Colleges
          </chakra.span>
        </Flex>

        <Flex
          p={2}
          borderRadius="full"
          minW="50px"
          flexDir="column"
          textAlign="center"
          bg="#F0F2F5"
        >
          <chakra.span fontSize="lg" fontWeight={600} h={5}>
            {props.n_advice}
          </chakra.span>

          <chakra.span fontSize="0.5rem" color="#414B5A">
            Q&As6 8 6{" "}
          </chakra.span>
        </Flex>

        <Flex
          p={2}
          borderRadius="full"
          minW="50px"
          flexDir="column"
          textAlign="center"
          bg="#F0F2F5"
        >
          <chakra.span fontSize="lg" fontWeight={600} h={5}>
            {props.n_ap_scores}
          </chakra.span>

          <chakra.span fontSize="0.6rem" color="#414B5A">
            AP/IBs
          </chakra.span>
        </Flex>

        <Flex
          p={2}
          borderRadius="full"
          minW="50px"
          flexDir="column"
          textAlign="center"
          bg="#F0F2F5"
        >
          <chakra.span fontSize="lg" fontWeight={600} h={5}>
            {props.n_essays}
          </chakra.span>

          <chakra.span fontSize="0.6rem" color="#414B5A">
            Essays
          </chakra.span>
        </Flex>

        <Flex
          p={2}
          borderRadius="full"
          minW="50px"
          flexDir="column"
          textAlign="center"
          bg="#F0F2F5"
        >
          <chakra.span fontSize="lg" fontWeight={600} h={5}>
            {props.n_awards}
          </chakra.span>

          <chakra.span fontSize="0.6rem" color="#414B5A">
            Awards
          </chakra.span>
        </Flex>

        <Flex
          p={2}
          borderRadius="full"
          minW="50px"
          flexDir="column"
          textAlign="center"
          bg="#F0F2F5"
        >
          <chakra.span fontSize="lg" fontWeight={600} h={5}>
            {props.n_ecs}
          </chakra.span>

          <chakra.span fontSize="0.6rem" color="#414B5A">
            Activities
          </chakra.span>
        </Flex>
      </Flex>

      <Text fontWeight="bold" mt={2} fontSize="xl">
        Acceptances
      </Text>

      {/* Don't show verification checks - it becomes too much for the UI */}
      <CollegeImageList
        colleges={
          props.colleges?.filter((college) => college.result === "Accepted")?.slice(0, 12) ?? []
        }
        enrolled={props.university}
      />
    </Flex>
  );
};

// deprecated
export const DeprecatedProfileCard = (props) => {
  const { id } = props;
  const router = useRouter();

  const onView = () => router.push(`/profile/${id}`);

  return (
    <Flex
      bg="white"
      borderRadius="lg"
      p={4}
      onClick={onView}
      cursor="pointer"
      direction="column"
      shadow="lg"
    >
      <Flex gap={4}>
        <ProfilePic key={id} uid={id} boxSize={20} />

        <Box>
          {/* TODO: PIN props.id === PINNED_PROFILE && <Icon name='pin' size='small' /> */}
          <Text fontWeight="bold">{props.display_name ?? "Anonymous"}</Text>
          <Flex align="center">
            <ConditionalVerifiedCheck
              verifications={props.verification}
              university={props.university}
              mr={1}
            />
            {colleges.find(props.university)?.name ?? "Unknown University"}{" "}
            {props.application_year && "‘" + (props.application_year % 100)}
          </Flex>
          {props.bio && <Text noOfLines={2}>{props.bio}</Text>}
        </Box>
      </Flex>
      <Text fontWeight="bold" mt={2}>
        Acceptances
      </Text>
      {/* Don't show verification checks - it becomes too much for the UI */}
      <CollegeImageList
        colleges={
          props.colleges?.filter((college) => college.result === "Accepted")?.slice(0, 12) ?? []
        }
        enrolled={props.university}
      />
    </Flex>
  );
};
