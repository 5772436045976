import { AnimatePresence, motion } from "framer-motion";

import OptimizedImage from "@/components/shared/OptimizedImage";

export const EssayCarousel = ({ secondVariation }) => {
  const image = secondVariation ? "-2" : "";

  return (
    <AnimatePresence mode="wait" style={{ position: "relative" }}>
      <motion.div
        initial={{ opacity: 0, x: "0px", y: "-60%" }}
        animate={{ opacity: 1, x: "0px", y: "30px" }}
        exit={{ opacity: 0, x: "0px", y: "30px" }}
        transition={{
          duration: 2,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <OptimizedImage
          src={`/images/home/essays/essay1${image}.png`}
          alt="Profiles"
          width="313px"
          height="250px"
          objectFit="cover"
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: "140px", y: "-400px" }}
        animate={{ opacity: 1, x: "140px", y: "-256px" }}
        exit={{ opacity: 0, x: "140px", y: "-300px" }}
        transition={{
          duration: 2,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{
          position: "absolute",
        }}
      >
        <OptimizedImage
          src={`/images/home/essays/essay2.png`}
          alt="Profiles"
          width="150px"
          height="46px"
          objectFit="cover"
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: "300px", y: "-440px" }}
        animate={{ opacity: 1, x: "300px", y: "-200px" }}
        exit={{ opacity: 0, x: "300px", y: "-140px" }}
        transition={{
          duration: 2,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{ position: "absolute" }}
      >
        <OptimizedImage
          src={`/images/home/essays/essay3${image}.png`}
          alt="Profiles"
          width="100px"
          height="74px"
          objectFit="cover"
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: "180px", y: "-440px" }}
        animate={{ opacity: 1, x: "180px", y: "-10px" }}
        exit={{ opacity: 0, x: "180px", y: "-10px" }}
        transition={{
          duration: 2,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{ position: "absolute" }}
      >
        <OptimizedImage
          src={`/images/home/essays/essay4${image}.png`}
          alt="Profiles"
          width="207px"
          height="92px"
          objectFit="cover"
        />
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: "-70px", y: "-440px" }}
        animate={{ opacity: 1, x: "-70px", y: "0px" }}
        exit={{ opacity: 0, x: "-70px", y: "-10px" }}
        transition={{
          duration: 2,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{ position: "absolute" }}
      >
        <OptimizedImage
          src={`/images/home/essays/essay5${image}.png`}
          alt="Profiles"
          width="164px"
          height="111px"
          objectFit="cover"
        />
      </motion.div>
    </AnimatePresence>
  );
};
