import { Grid } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { isDev } from "@/utils/data/constants";
import { downloadAndCache } from "../profile/ProfilePic";
import { DeprecatedProfileCard, ProfileCard } from "../ProfileCard";
import { EssayCarousel } from "./Hero/EssayCarousel";

export const SingleProductCarousel = ({
  profiles,
  includeEssay = false,
  nIterations,
  setNIterations,
}) => {
  const [currentProfile, setCurrentProfile] = useState(0);

  useEffect(() => {
    const interval = window.setInterval(() => {
      setCurrentProfile((i) => (i + 1) % profiles.length);

      const nextProfile = (currentProfile + 2) % profiles.length;

      if (profiles[nextProfile]) {
        downloadAndCache(profiles[nextProfile].id, true);
      }

      setNIterations((i) => i + 1);
    }, 5000);

    return () => window.clearInterval(interval);
  }, []);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={profiles[currentProfile]?.id ?? "none"}
        transition={{ ease: "easeIn" }}
        style={{ transformOrigin: "bottom left" }}
        initial={nIterations === 0 ? { opacity: 1, y: 0 } : { opacity: 0, y: "-5%" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "40%" }}
      >
        {nIterations % 3 === 2 && includeEssay ? (
          <EssayCarousel />
        ) : nIterations % 4 === 3 && includeEssay ? (
          <EssayCarousel secondVariation />
        ) : (
          <ProfileCard {...profiles[currentProfile]} />
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export const MultiProfileCarousel = ({ profiles }) => {
  const [curGroup, setCurGroup] = useState(0);
  const groupSize = 3;
  const groups = [];

  for (var i = 0; i < profiles.length; i += groupSize) {
    const newGroup = [];
    for (var j = 0; j < groupSize; j++) {
      if (profiles[i + j]) {
        newGroup.push(profiles[i + j]);
      }
    }
    groups.push(newGroup);
  }

  useEffect(() => {
    const interval = window.setInterval(() => {
      setCurGroup((i) => (i + 1) % groups.length);
    }, 5000);
    return () => window.clearInterval(interval);
  }, []);

  return (
    <Grid
      my={12}
      color="initial"
      rowGap={{ base: 4, md: 0 }}
      templateColumns={{ base: "1fr", md: `repeat(${groupSize}, 1fr)` }}
      columnGap={6}
    >
      <AnimatePresence mode="wait">
        {!isDev &&
          groups[curGroup].map((profile, i) => (
            <motion.div
              key={profile.id + curGroup + i}
              transition={{ delay: i * 0.1, ease: "easeOut" }}
              initial={{ opacity: 0, x: "50%" }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: "-50%" }}
            >
              <DeprecatedProfileCard key={i} {...profile} />
            </motion.div>
          ))}
      </AnimatePresence>
    </Grid>
  );
};

// deprecated
export const SingleProfileCarousel = ({ profiles }) => {
  const [currentProfile, setCurrentProfile] = useState(0);
  const [nIterations, setNIterations] = useState(0);

  useEffect(() => {
    const interval = window.setInterval(() => {
      setCurrentProfile((i) => (i + 1) % profiles.length);
      const nextProfile = (currentProfile + 2) % profiles.length;
      if (profiles[nextProfile]) {
        downloadAndCache(profiles[nextProfile].id, true);
      }
      setNIterations((i) => i + 1);
    }, 5000);
    return () => window.clearInterval(interval);
  }, []);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={profiles[currentProfile]?.id ?? "none"}
        transition={{ ease: "easeIn" }}
        style={{ transformOrigin: "bottom left" }}
        initial={nIterations === 0 ? { opacity: 1, y: 0 } : { opacity: 0, y: "-5%" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "40%" }}
      >
        <DeprecatedProfileCard {...profiles[currentProfile]} />
      </motion.div>
    </AnimatePresence>
  );
};
