import { Box, Flex, Image } from "@chakra-ui/react";
import { useMemo } from "react";
import { find } from "@/utils/data/colleges-data";
import { COLLEGE_RESULTS_SORTING } from "@/utils/profile";
import { ConditionalVerifiedCheck } from "../profile/VerifiedCheck";
import OptimizedImage from "../shared/OptimizedImage";

const sortByRanking = (a, b) => {
  return find(a).rank - find(b).rank;
};

const sortByResultsAndRanking = (a, b, enrolled) => {
  if (a.name === enrolled || find(a.name).name === enrolled) {
    return -1;
  } else if (b.name === enrolled || find(b.name).name === enrolled) {
    return 1;
  }
  if (a.result === "") {
    return 1;
  } else if (b.result === "") {
    return -1;
  }

  const resultA = COLLEGE_RESULTS_SORTING[a.result];
  const resultB = COLLEGE_RESULTS_SORTING[b.result];
  if (resultA !== resultB) {
    return resultA - resultB;
  } else {
    return find(a.name).rank - find(b.name).rank;
  }
};

export const CircleBorderWrapper = ({
  circular,
  circleBackgroundColor,
  circleBorderColor,
  size,
  children,
}) => {
  if (circular) {
    return (
      <Box bg={circleBackgroundColor} borderRadius="full" border={`1px solid ${circleBorderColor}`}>
        <Box m={3} pos="relative" w={size} h={size}>
          {children}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box pos="relative" w={size} h={size}>
        {children}
      </Box>
    );
  }
};

const CollegeImageList = ({
  colleges,
  onlyNames,
  verification,
  enrolled,
  size = 9,
  collegeLimit,
  circular = false,
  circleBorderColor = "black",
  circleBackgroundColor = "white",
  children,
  ...rest
}) => {
  const sortedColleges = useMemo(
    () =>
      [...colleges]
        .sort((a, b) => {
          if (onlyNames) {
            return sortByRanking(a, b);
          } else {
            return sortByResultsAndRanking(a, b, enrolled);
          }
        })
        .slice(0, collegeLimit),
    [colleges],
  );

  return (
    <>
      <Flex wrap="wrap" display="flex" gap={1} mt={1} {...rest}>
        {sortedColleges.map((college, i) => (
          <CircleBorderWrapper
            circular={circular}
            circleBorderColor={circleBorderColor}
            circleBackgroundColor={circleBackgroundColor}
            size={size}
            key={i}
          >
            <OptimizedImage
              layout="fill"
              display="inline"
              src={find(college.name ?? college)?.image}
              alt={college.name ?? college}
              title={college.name ?? college}
            />
            <ConditionalVerifiedCheck
              verifications={verification}
              university={college.name}
              pos="absolute"
              bottom={circular ? -4 : { base: -0.5, md: -1 }}
              right={circular ? -4 : -2}
              size={circular ? "1.5em" : "1.25em"}
              zIndex={1}
              transform="auto"
            />
          </CircleBorderWrapper>
        ))}
        {children}
      </Flex>
    </>
  );
};

export default CollegeImageList;
