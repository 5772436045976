import { Box, Flex, chakra, Text } from "@chakra-ui/react";
import OptimizedImage from "@/components/shared/OptimizedImage";

export const TestimonialList = ({ rawTestimonials, bg = "gray.100", color = "white", ...rest }) => {
  const columns = Object.values(rawTestimonials);

  return (
    <Flex
      flexDir={{ base: "column", md: "row" }}
      alignItems={{ base: "center", md: "normal" }}
      gap={8}
      {...rest}
    >
      {columns.map((col, i) => (
        <Flex direction="column" justifyContent="space-between" gap={{ base: 8, md: 4 }} key={i}>
          {col.map((testimonial, j) => (
            <Testimonial key={i * col.length + j} {...testimonial} bg={bg} color={color} />
          ))}
        </Flex>
      ))}
    </Flex>
  );
};

export const Testimonial = ({ content, role, image, name, bg, ...rest }) => (
  <Box mt={4} {...rest}>
    <Box
      bg={bg}
      p={8}
      borderRadius="0.5rem"
      border="1px solid #454562"
      boxShadow="-4px 4px 0px 0px #454562"
      maxWidth={{ base: "100%", md: "28rem" }}
      position="relative"
    >
      <Box position="absolute" bottom="-2.67em" left="-1">
        <OptimizedImage
          src="/images/home/testimonial-arrow.svg"
          width={80}
          height={50}
          alt="arrow"
        />
      </Box>

      <chakra.p>{content}</chakra.p>

      <Box opacity="0.1" my={5} width="100%" border="0.5px solid #F0F2F5" />

      <Flex gap={4} align="center">
        <OptimizedImage
          src={`/images/testimonials/${image ?? "default"}.jpg`}
          alt={`${name}, ${role}`}
          width={12 * 4}
          height={12 * 4}
          borderRadius="full"
          border="1px solid #ffffff44"
        />

        <Box>
          <Text fontSize="xl" fontWeight="bold">
            {name}
          </Text>

          <Text fontSize="md">{role}</Text>
        </Box>
      </Flex>
    </Box>
  </Box>
);

export const DeprecatedTestimonial = ({ content, role, image, name, bg, ...rest }) => (
  <Box mt={4} {...rest}>
    <Box bg={bg} p={4} borderRadius="md">
      {content}
    </Box>
    <Flex mt={2} gap={2} align="center">
      <OptimizedImage
        src={`/images/testimonials/${image ?? "default"}.jpg`}
        alt={`${name}, ${role}`}
        width={12 * 4}
        height={12 * 4}
        borderRadius="full"
        border="1px solid #ffffff44"
      />
      <Box>
        <Text fontSize="xl" fontWeight="bold">
          {name}
        </Text>
        <Text fontSize="md">{role}</Text>
      </Box>
    </Flex>
  </Box>
);
